//Filter Sidebar Style

.sidebarWrapper {
    .sidebarCard {
        box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);

        .cardInner {
            .filters {
                .ant-collapse {
                    background: $white;
                    .ant-collapse-item {
                        margin: 1rem 0;
                    }
                }
                .filterLabel {
                    display: block;
                    color: $black;
                    font-weight: 600;
                    margin-bottom: 0.5rem;
                }
                .formControl {
                    width: 100%;

                    .ant-select-selector {
                        width: 100%;
                        height: 100%;
                        border: 0;

                        input {
                            height: 100%;
                        }

                        .ant-select-selection-placeholder {
                            line-height: 42px;
                        }
                    }
                }

                .filterDrop {
                    .ant-collapse-header {
                        padding: 0;
                        background: $white;

                        .ant-collapse-header-text {
                            color: $black;
                            font-weight: 600;
                            margin-bottom: 1rem;
                        }
                    }

                    .ant-collapse-item-active {
                        .ant-collapse-expand-icon {
                            padding: 0;
                            transform: rotate(180deg);
                        }
                    }

                    .ant-collapse-content {
                        background: $white;

                        .ant-collapse-content-box {
                            padding: 0.625rem 0;

                            .ant-checkbox-wrapper {
                                width: 100%;
                                margin-inline-start: 0;
                                margin-bottom: 0.625rem;
                            }
                        }
                    }
                }
            }
        }
    }
}