//Contact Us page style

.contactWrapper {
    .contactInnerWrap {
        .contentWrap {
            padding: 3.125rem 0;

            .leftWrapper {
                .leftSecTitle {
                    font-size: 2.125rem;
                    color: $primary;
                    margin-bottom: 2rem;
                }
                .contactCardSec {
                    .contactCard {
                        box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);
                        margin-bottom: 1.5rem;

                        .ant-card-body {
                            padding: 0 1rem;
                        }
                        .cardInner {
                            .topSection{
                                background: $primary;
                                border-radius: 0 0 0.625rem 0.625rem;
                                padding: 0.75rem 0;
                                text-align: center;
                                box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 58px;

                                .cardIcon {
                                    font-size: 2.5rem;
                                    color: $white;
                                    line-height: 0;
                                }
                            }

                            .bottomSection {
                                padding: 1rem 0;
                                text-align: center;

                                .cardTitle {
                                    font-weight: bold;
                                    margin-bottom: 1rem;
                                }

                                .cardText {
                                    font-size: 1.125rem;
                                    font-weight: 500;
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }

            .rightWrapper {
                .rightSecDesc {
                    font-size: 1.125rem;
                    margin-bottom: 2rem;
                }

                .contactCardWrap {
                    .contactFormCard {
                        .cardInner {
                            .formTitle {
                                text-align: center;

                                h4 {
                                    color: $primary;
                                    font-size: 1.875rem;
                                }

                                p {
                                    font-size: 1.125rem;
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}