//Faqs page style

.faqWrapper {
    .faqInnerWrap {
        .faqWrap {
            padding: 3.125rem 0;

            .leftWrapper {
                .leftSecTitle {
                    font-size: 2.125rem;
                    color: $primary;
                    margin-bottom: 2rem;
                }
            }

            .rightWrapper {
                .rightSecDesc {
                    font-size: 1.125rem;
                    margin-bottom: 2rem;
                }
            }

            .faqSection {
                margin: 1.5rem 0;
                .ant-collapse {
                    background: $white;

                    .ant-collapse-item {
                        box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);
                        margin-bottom: 1.5rem;
                        border-radius: 0.625rem;
                        border-bottom: 0;
    
                        .ant-collapse-header {
                            background: $white;
                            border-radius: 0.625rem;
        
                            .ant-collapse-header-text {
                                color: $black;
                                font-weight: 600;
                            }
                        }
        
                        .ant-collapse-content {
                            background: $white;
                            border-bottom-left-radius: 0.625rem;
                            border-bottom-right-radius: 0.625rem;
        
                            .ant-collapse-content-box {
                                padding: 0.75rem 1rem;
        
                                .ant-checkbox-wrapper {
                                    width: 100%;
                                    margin-inline-start: 0;
                                    margin-bottom: 0.625rem;
                                }
                            }
                        }
                    }

                    .ant-collapse-item-active {
                        .ant-collapse-expand-icon {
                            padding: 0;
                            padding-inline-start: 0;
                            transform: rotate(180deg);
                        }
                    }
                }
            }

            .cmsCard {
                box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);
            }
        }
    }
}
