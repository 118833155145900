//Inbox Page Style

.inboxWrapper {
    .contentWrap {
        padding: 1.5rem 0;

        .tableWrap {
            .tableCard {
                box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);

                .ant-card-head {
                    background: $tag;

                    .ant-card-head-title {
                        font-weight: 700;
                    }
                }

                .ant-card-body {
                    padding: 1rem;
                    overflow: scroll;
                    .inboxDatatable {
                        table {
                            .ant-table-thead {
                                 tr {
                                    th {
                                        background: $white;
                                        font-weight: 600;
                                        &::before {
                                            display: none;
                                        }
                                    }
                                }
                            }

                            .ant-table-tbody {
                                tr {
                                    &:nth-of-type(odd) {
                                        background: $tag;
                                    }
                                    &:last-child() {
                                        border-bottom: 0;
                                    }
                                }
                            }
                        }
                    }

                    .cardInner {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        transition: all ease 0.5s;
                        .msgThread {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            gap: 0.25em;
                            padding: 1em;
                            overflow: auto;

                            .msgText {
                                width: fit-content;
                                margin-bottom: 0.5rem;
                                animation: appear-msg ease 0.25s forwards;
                                .text {
                                    white-space: break-spaces;
                                    line-height: 12px;
                                    font-size: 14px;
                                    display: block;
                                    padding: 0.375em;
                                    border-radius: 0.75em 0.75em 0.75em 0;
                                    background: $tag;
                                    box-shadow: 0 2px 0 #00000020;
                                }
                            }
                        }
                    }
                }
            }

            .inboxCard {
                .ant-card-body {
                    padding: 0;

                    .cardInner {
                        .msgThread {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            gap: 0.25em;
                            padding: 1em;
                            overflow: auto;

                            .msgText {
                                &.sendar{
                                    margin-left: auto;
                                    .text {
                                        color: $white;
                                        background: $primary;
                                        border-radius: 0.75em 0.75em 0 0.75em;
                                    }
                                }
                                width: fit-content;
                                font-size: 1rem;
                                margin-bottom: 0.5rem;
                                animation: appear-msg ease 0.25s forwards;
                                .text {
                                    display: block;
                                    padding: 0.75em;
                                    border-radius: 0.75em 0.75em 0.75em 0;
                                    background: $tag;
                                    box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);
                                }
                            }
                        }
                        .sendField {
                            width: 100%;
                            height: 4em;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 0.5em;
                            padding: 0.25em 0.5em;
                            border-top: 1px solid #f0f0f0;
                            transition: all ease 0.5s;

                            .ant-form-item {
                                all: unset;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }


    }
}

.chatAvt {
    background-color: $primary;
    color: $white;
    font-size: 1rem;
    margin-right: 0.5rem;
}