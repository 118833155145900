* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $Open, sans-serif !important;
}

body {
  font-family: $Open, sans-serif !important;
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

.dFlexBet {
  display: flex !important;
  align-items: center;
}

.dFlexCode {
  display: flex !important;
}

.formControl {
  height: 45px;
  border: 0;
  border-radius: 10px;
  box-shadow: 0px 2px 9.5px 0.5px rgba(0, 0, 0, 0.14);
}

.disableFormControl {
  height: 45px;
  border: 0;
  border-radius: 10px;
  padding: 0.25rem 0688rem;
}

.link {
  color: $primary;
  text-decoration: none;
}

.planLink {
  font-weight: 600;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.dropLink {
  font-weight: 600;
  text-decoration: none;
}

.noFlex {
  display: block !important;
}

.logOutBtn {
  all: unset;
  width: 100%;
  font-weight: 600;
}

.forgotLink {
  color: $black;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  position: relative;
  top: 3px;

  &:hover {
    color: $primary;
  }
}

.w-30 {
  width: 30% !important;
}

.w-70 {
  width: 70% !important;
}

.spinDiv {
  width: 100%;
  height: 100%;
  padding: 100px 50px;
  text-align: center;
}

.repeater {
  .ant-space-item {
    width: 100%;
  }
}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background-color: $primary;
}

.ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  top: -1rem;
}

.w-120 {
  width: 120px !important;
}

.displayHide {
  display: none !important;
}

.relative {
  position: relative;

  .upgradeSection {
    position: absolute;
    top: -3rem;
    right: 1rem;
    background: $white;
    display: inline;
  }
}

.bgGreen {
  background: $success !important;
  border-color: $success !important;

  &:hover {
    color: $white !important;
  }
}

.clientDashWrapper {
  .dashSidebar {
    box-shadow: 0px 2px 9.5px 0.5px rgba(0, 0, 0, 0.1);

    .custAvatar {
      padding: 2rem 1rem 0;
      border-bottom: 1px solid $priceCard;

      .userData {
        color: $white;

        h6 {
          margin-bottom: 0;
        }
      }
    }

    .ant-menu {
      padding: 1rem 0.125rem;
      background: $primary;

      .ant-menu-item {
        color: $white;

        &.ant-menu-item-selected {
          background-color: $priceCard;
        }

        .siderLink {
          text-decoration: none;
        }
      }
    }
  }

  .pageTitle {
    display: flex;
    align-items: center;

    .breadcrumbIcon {
      width: 35px;
      height: 35px;
      font-size: 1rem;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.625rem;
      background: $primary;
      margin-right: 1rem;
      box-shadow: 0px 2px 12.18px 1.82px rgba(0, 0, 0, 0.14);
    }

    h6 {
      margin-bottom: 0;
    }
  }

  .breadFlex {
    display: flex !important;
    justify-content: flex-end;
    align-items: center;
  }

  .dashContent {
    padding-top: 2rem;
    background: $white;

    .innerCard {
      .ant-card {
        // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        margin-bottom: 2rem;

        .ant-card-body {
          padding: 0;

          .innerContent {
            display: flex;
            flex-wrap: wrap;

            .saveArtist {
              .ant-image {
                width: 99%;
                height: 100%;
              }

              // flex: 0 0 50%;
              // max-width: 100%;
              // width: 50%;
            }
          }

          .ant-card-meta {
            padding: 0.5rem 0.5rem 1rem 0.5rem;
            text-align: center;
          }
        }
      }
    }
  }
}

.ant-image-preview-img-wrapper {
  .ant-image-preview-img {
    width: 100% !important;
    max-width: 800px !important;
    height: 100% !important;
    object-fit: contain !important;
  }
}

.ant-drawer-content-wrapper {
  width: 240px !important;
  background: '#d62b47' !important;
  border-radius: '10px';

  .ant-drawer-content {
    .ant-drawer-content {
      padding: 0 !important;
    }
  }

  .homebMenu {
    .ant-drawer-wrapper-body {
      .ant-drawer-header-close-only {
        border: 0 !important;
        padding: 0;

        .ant-drawer-header-title {
          justify-content: flex-end;

          .ant-drawer-close {
            margin-right: 0;
            background: $primary;
            border-radius: 5px;
            padding: 0.5rem;
            color: $white;
          }
        }
      }

      .ant-drawer-body {
        padding: 0px !important;

        .ant-menu-item {
          padding-inline: 0px !important;
        }
      }
    }
  }
}

.unsaveConfirmBtn {
  background-color: #d62b47;
  color: white;
  border: none;
  padding: 0.6rem 1rem;
  margin-right: 2rem;
  border-radius: 25px;
  transition: 0.2s all ease-in-out;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transform: translateY(-0.15em);
  }
}

.unsaveCancelBtn {
  background-color: #fff;
  border: 1px solid #d62b47;
  color: #d62b47;
  padding: 0.6rem 1rem;
  border-radius: 25px;
  transition: 0.2s all ease-in-out;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transform: translateY(-0.15em);
  }
}

.artistRemovedBtn {
  background-color: #d62b47;
  color: white;
  border: none;
  padding: 0.6rem 2rem;
  border-radius: 25px;
  transition: 0.2s all ease-in-out;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transform: translateY(-0.15em);
  }
}
@media (max-width:574px){
  .Layout-mobile{
    display: none !important;
// flex-direction: column !important;
  }
}

@media (max-width:580px) {
  .ant-steps-item-tail {
    display: none !important;
  }
  .ant-steps.ant-steps-vertical {
    display: flex;
    flex-direction:row;
   
  }
  .ant-steps-item {
    flex : 0 auto !important;
  }
}
.mobUI {
  display: flex !important;
}
.breadcrLink {
  text-decoration: none;
  color: #d62b47 !important;
}

.fileList {
  display: flex;
  gap: 10px;

  .preImg {
    width: 100px;
    height: 100px;
    position: relative;
    border: 1px dashed #d62b47;
    border-radius: 8px;
    overflow: hidden;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    &:hover {
      .ant-image {
        position: relative;
          &::before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            padding-top:40px;
            background: rgba(0, 0, 0, 0.5);
          }
      }
      .dltBtn {
        width: 100%;
        height: 100%;
        opacity: 1;
        color: #FFF;
      }
    }
    .dltBtn {
      position: absolute;
      top: 0;
      left: 0;
      background: transparent;
      border: 0;
      padding: 0;
      margin: auto;
      right: 0;
      bottom: 0;
      opacity: 0;
    }
  }
}