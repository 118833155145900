//PRicinig Page Style

.pricingWrapper {
    min-height: 70vh;
    .innerWrap{
        padding: 1.5rem 0;

        .titleRow {
            text-align: center;
            margin-bottom: 1.5rem;

            .pageTitle {
                color: $primary;
                font-weight: 600;
                font-size: 2.125rem;
            }
        }

        // .ant-row {
        //     .ant-col {
        //         &:nth-child(1) {
        //             order: 1;
        //         }
        //         &:nth-child(2) {
        //             order: 3;
        //         }
        //         &:nth-child(3) {
        //             order: 2;
        //         }
        //     }
        // }

        .pricingCard {
            &.premiumBG {
                background: $primary;
            }
            background: $priceCard;
            border-radius: 1.5rem;
            height: 100%;
            box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);
            transition: all 0.3s ease-in-out;
            &:hover {
                transform: scale(1.01);
                box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.3);
            }
            

            .ant-card-body {
                padding: 1rem 0;
            }

            .cardHeader {
                padding: 1rem;

                .priceSection {
                    display: flex;

                    .priceCrcl {
                        width: 100px;
                        height: 100px;
                        border: 3px solid $white;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 1rem;
                        background: $primary;

                        @media only screen and (min-width: 768px) and (max-width: 991px) {
                            width: 50px;
                            height: 50px;
                        }

                        .price {
                            font-size: 1.5rem;
                            font-weight: bold;
                            color: white;

                            @media only screen and (min-width: 768px) and (max-width: 991px) {
                                font-size: 1rem;
                            }
                        }
                    }

                    .pricingPlan {
                        display: flex;
                        justify-content: center;
                        flex-direction: column;

                        p {
                            font-size: 2rem;
                            color: $white;
                            font-weight: bold;
                            margin-bottom: 0;
                            text-transform: uppercase;
                            line-height: 2.5rem;
                           
                            @media only screen and (min-width: 768px) and (max-width: 991px) {
                                font-size: 1rem;
                                line-height: 1.5rem;
                            }
                        }

                        .small {
                            font-size: 0.75rem;
                            color: $white;
                        }
                    }
                }
            }

            .cardInner {
                padding: 0 1rem;

                .innerCardwrap {
                    padding: 2rem 1rem;
                    background: $white;
                    border-radius: 1rem;
                    box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);

                    .description {
                        text-align: center;
                        font-weight: 600;
                        margin-bottom: 2rem;
                    }

                    .planDetails {
                        margin-bottom: 1rem; 
                        .dtList {
                            text-align: center;
                            font-weight: 600;  
                            margin-bottom: 0.5rem; 

                            .icnDt {
                                color: $danger;
                                font-size: 1.5rem;
                                margin-right: 0.5rem;
                            }

                            .inClr {
                                color: $success;
                            }
                        }
                    }

                    .planBtn {
                        text-align: center;
                        padding-top: 2rem;
                       
                    }
                    .btnCard{
                        padding-top: 75px;
                    }
                }
            }
        }
        .filerSection {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .ant-col {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                .ant-select{
                    width: 140px;
                .ant-select-selector {
                    background-color: #d62b47;
                    color: white;
                    
                }
                .ant-select-arrow {
                    color: white;
                }
            }
            }
        }
    }
}

.selectPlan  {
    .ant-radio-button-wrapper-checked {
        border-color: $primary !important;
        color: $primary;
    }
    .ant-radio-button-wrapper {
        &:hover {
            color: $primary;
        }
    }
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
    background-color: $primary;
}

.formWrapper {
    padding: 1rem;
    background: $tag;
}
