//Checkout Page Style

.paymentWrapper {
    .innerWrapper {
        padding: 1.5rem 0;

        .paymentCard {
            box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);
            .formGroup {
                margin-bottom: 1rem;

                label {
                    margin-bottom: 0.625rem;
                }

                .customFormControl {
                    height: 45px;
                    border: 0;
                    border-radius: 10px;
                    box-shadow: 0px 2px 9.5px 0.5px rgba(0, 0, 0, 0.14);
                }
            }
        }
    }
}