.PastArtist {
  .artistContainer {
    margin: 1rem 0;

    .liistWrapper {
      .ant-card {
        border-radius: 8px !important;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        padding: 1rem;

        &:hover {
          .ant-card-actions {
            opacity: 1;
          }

          .ant-card-cover {
            img {

              -webkit-filter: brightness(50%);
              -webkit-transition: all 0.6s ease;
              -moz-transition: all 0.6s ease;
              -o-transition: all 0.6s ease;
              -ms-transition: all 0.6s ease;
              transition: all 0.6s ease;
            }
          }
        }

        .ant-card-cover {
          position: relative;

          .ant-image {
            height: 300px;
            overflow: hidden;
          }

          img {
            border-radius: 8px !important;
            object-fit: fill;
            height: 100%;
          }
        }

        .ant-card-actions {
          position: absolute;
          top: 20%;
          left: 0;
          right: 0;
          background: transparent;
          border: 0;
          opacity: 0;
          display: flex;
          flex-direction: column;

          li {
            border-inline-end: 0;
            width: 100% !important;
            position: relative;

            button {
              margin-bottom: 0 !important;
              background: transparent;
            }

            .artistName {
              border: none;
              color: white;
              font-size: 30px;
              font-weight: 700;
            }

            .reviewBtn {
              background: rgb(214, 43, 71);
              color: white;
              border: none;
              box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
              transition: 0.2s all ease-in-out;

              &:hover {
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                transform: translateY(-0.15em);
              }
            }
          }
        }

        .ant-card-body {
          display: none;
        }
      }
    }
  }
}