//Authentication Style

.authWrapper {
    position: relative;
    display: block;

    .authWrapInner {
        padding: 3rem 0;

        .authCard {
            box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.2);
            overflow: hidden;

            .ant-card-body {
                padding: 0;
            }

            .leftSection {
                @media only screen and (max-width: 767px) {
                    padding: 3rem;
                }
                .contentSection {
                    text-align: center;
                    color: $white;

                    .secTitle {
                        font-size: 3rem;
                        font-weight: bold;
                    }

                    .subText {
                        font-size: 1rem;
                    }
                }
            }

            .rightSection {
                padding: 3.125rem;

                @media only screen and (min-width: 768px) and (max-width: 991px) {
                    padding: 1.125rem;
                }
                
                @media only screen and (max-width: 767px) {
                    padding: 2rem 1rem;
                }

                .formWrap {
                    .formTitle {
                        h3 {
                            font-size: 1.875rem;
                            text-align: center;
                            padding-bottom: 2rem;
                        }
                    }
                    .forgotRow {
                        .ant-form-item-control-input-content {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        }
                    }

                    .orSection {
                        p {
                            padding: 1.5rem 0;
                            margin-bottom: 0;
                            text-align: center;
                            font-size: 1rem;
                            position: relative;
                            &::after {
                                content: '';
                                display: inline-block;
                                position: absolute;
                                width: 100px;
                                height: 1px;
                                background: $black;
                                top: 50%;
                                right: 10%;
                            }
                            &::before {
                                content: '';
                                display: inline-block;
                                position: absolute;
                                width: 100px;
                                height: 1px;
                                background: $black;
                                top: 50%;
                                left: 10%;
                            }
                            @media only screen and (min-width: 768px) and (max-width: 991px) {
                                &::after {
                                    right: 3%;
                                }
                                &::before {
                                    left: 3%;
                                }
                            }

                            @media only screen and (max-width: 767px) {
                                &::after {
                                    right: 3%;
                                }
                                &::before {
                                    left: 3%;
                                }
                            }
                        }
                    }

                    .socialLoginBtn {
                        text-align: center;

                        .sclLogBtn {
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            width: 45px;
                            height: 45px;
                            border-radius: 10px;
                            box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);
                            font-size: 1.125rem;
                            margin-right: 0.625rem;
                        }
                    }

                    .privacyLinkRow {
                        padding: 1.5rem 0 0 0;
                        p {
                            margin-bottom: 0;
                            a {
                                color: $primary;
                                transition: all 0.3s ease-in-out;
                                text-decoration: none;
                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
            }

            .secPadding {
                padding: 7rem 3.125rem;

                .frmTitle {
                    padding-bottom: 1rem !important;
                }

                .subTitle {
                    text-align: center;
                }
            }
        }
    }
}

.switchBtnSec {
    margin: 1rem 0;
}