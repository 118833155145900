//Button Style

//Primary Button
.btnPrimary {
    width: 100%;
    font-size: 1rem;
    text-align: center;
    color: $white;
    border-radius: 10px;
    background: $primary;
    padding: 0.313rem 1rem;
    border: 1px solid $primary;
    transition: all 0.3s ease-in-out;
    margin-right: 0.625rem;
    box-shadow: 0px 2px 9.5px 0.5px rgba(0, 0, 0, 0.14);
    &:hover,
    &:active {
        color: $primary;
        background: $white;
        border: 1px solid $primary;
        span {
            color: $primary;
        }
    }
    &:last-child {
        margin-right: 0;
    }
}

//Submit Button
.submitBtn {
    width: 100%;
    height: auto;
    font-size: 1rem;
    text-align: center;
    color: #FFFFFF;
    border-radius: 10px;
    background: #d62b47;
    padding: 0.18rem 1rem;
    border: 1px solid #d62b47;
    transition: all 0.3s ease-in-out;
    margin-right: 0.625rem;
    box-shadow: 0px 2px 9.5px 0.5px rgba(0, 0, 0, 0.14);
    &:hover,
    &:active {
        color: $primary !important;
        background: $white !important;
        border: 1px solid $primary !important; 
        span {
            color: $primary;
        }
    }
    &:last-child {
        margin-right: 0;
    }
    .anticon {
        vertical-align: 0;
    }
}

.btnSecondary {
    width: 100%;
    font-size: 1rem;
    text-align: center;
    color: $black;
    border-radius: 10px;
    background: $white;
    padding: 0.313rem 1rem;
    border: 1px solid $tag;
    transition: all 0.3s ease-in-out;
    margin-right: 0.625rem;
    box-shadow: 0px 2px 9.5px 0.5px rgba(0, 0, 0, 0.14);
    &:hover,
    &:active {
        color: $white;
        background: $black;
        border: 1px solid $tag;
    }
    &:last-child {
        margin-right: 0;
    }
}

.bgDark {
    background: $white;
    color: $black;
    border: 1px solid $black;
    border-radius: 5px;
    &:hover,
    &:active {
        color: $white;
        background: $black;
        border: 1px solid $black;
        span {
            color: $white;
        }
    }
}

.btnNext {
    all: unset;
    width: 100%;
    font-size: 1rem;
    text-align: center;
    color: $white;
    border-radius: 10px;
    background: $primary;
    padding: 0.313rem 1rem;
    border: 1px solid $primary;
    transition: all 0.3s ease-in-out;
    margin-right: 0.625rem;
    box-shadow: 0px 2px 9.5px 0.5px rgba(0, 0, 0, 0.14);
    &:hover,
    &:active {
        color: $primary;
        background: $white;
        border: 1px solid $primary !important;
        span {
            color: $primary;
        }
    }
    &:last-child {
        margin-right: 0;
    }
}

.btnBack {
    all: unset;
    width: 100%;
    font-size: 1rem;
    text-align: center;
    color: $black;
    border-radius: 10px;
    background: $white;
    padding: 0.313rem 1rem;
    border: 1px solid $tag;
    transition: all 0.3s ease-in-out;
    margin-right: 0.625rem;
    box-shadow: 0px 2px 9.5px 0.5px rgba(0, 0, 0, 0.14);
    &:hover,
    &:active {
        color: $white !important;
        background: $black;
        border: 1px solid $tag !important;
    }
    &:last-child {
        margin-right: 0;
    }
}
.backBtn {
    background-Color:"#655e5e";
     color:"white"
}

.editBtn {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background: $white;
    border-color: $white;
    box-shadow: 0px 2px 9.5px 0.5px rgba(0, 0, 0, 0.1);
    color: $primary;
}

.switchBtn {
    all: unset;
    width: auto;
    border: 1px solid $primary;
    background: $white;
    color: $primary;
    border-radius: 10px;
    color: $primary;
    padding: 0.313rem 1rem;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 2px 9.5px 0.5px rgba(0, 0, 0, 0.1);
    &:hover {
        background: $primary;
        color: $white;
    }
}

.seenMsg {
    background: #eaeaea !important;
    border-color: #eaeaea !important;
    color: $black;
}

.backBtn {
    all: unset;
    width: auto;
    font-size: 1rem;
    text-align: center;
    color: $white;
    border-radius: 10px;
    background: #655e5e;
    padding: 0.313rem 1rem;
    border: 1px solid $tag;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 2px 9.5px 0.5px rgba(0, 0, 0, 0.14);
    &:hover,
    &:active {
        color: $white !important;
        background: $black;
        border: 1px solid $tag !important;
    }
}