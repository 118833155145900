.innerCard {
  .savedCard {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    // margin-right: 1rem;
    cursor: pointer;
    transition: 0.2s all ease-in-out;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
    }

    .saveArtist {
      width: 50% !important;
      padding: 0.5rem;
    }

    .ant-card {
      border-radius: 8px !important;

      &:hover {
        .ant-card-actions {
          opacity: 1;
        }

        .ant-card-cover {

          img {
            -webkit-filter: brightness(50%);
            -webkit-transition: all 0.6s ease;
            -moz-transition: all 0.6s ease;
            -o-transition: all 0.6s ease;
            -ms-transition: all 0.6s ease;
            transition: all 0.6s ease;
          }
        }

      }

      .ant-card-cover {
        position: relative;

        img {
          border-radius: 8px !important;
        }
      }

      .ant-card-actions {
        position: absolute;
        top: 40%;
        left: 0;
        right: 0;
        background: transparent;
        border: 0;
        opacity: 0;
        display: flex;
        flex-direction: row;

        li {
          border-inline-end: 0;
          width: 100% !important;
          position: relative;

          button {
            margin-bottom: 0 !important;
            background: transparent;
          }

          .artistName {
            border: none;
            color: white;
            font-size: 30px;
            font-weight: 700;
          }
        }
      }

      .ant-card-body {
        display: none;
      }
    }
  }
}


.cardImgWrapper {
  .ant-card {
    border-radius: 8px !important;
    padding: 1rem;

    &:hover {
      .ant-card-actions {
        opacity: 1;
      }

      .ant-card-cover {
        img {

          -webkit-filter: brightness(50%);
          -webkit-transition: all 0.6s ease;
          -moz-transition: all 0.6s ease;
          -o-transition: all 0.6s ease;
          -ms-transition: all 0.6s ease;
          transition: all 0.6s ease;
        }
      }
    }

    .ant-card-cover {
      position: relative;

      img {
        border-radius: 8px !important;
      }
    }

    .ant-card-actions {
      position: absolute;
      top: 40%;
      left: 0;
      right: 0;
      background: transparent;
      border: 0;
      opacity: 0;
      display: flex;
      flex-direction: row;

      li {
        border-inline-end: 0;
        width: 100% !important;
        position: relative;

        button {
          margin-bottom: 0 !important;
          background: transparent;
        }

        .artistName {
          border: none;
          color: white;
          font-size: 30px;
          font-weight: 700;
        }
      }
    }

    .ant-card-body {
      display: flex;
      justify-content: center;
      align-items: center;

      .cardTitle {
        .ant-card-meta-detail {
          .ant-card-meta-title {
            font-size: 18px;
          }
        }
      }
    }
  }

  .ant-card-actions {
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    background: transparent;
    border: 0;
    opacity: 0;
    display: flex;
    flex-direction: row;

    li {
      border-inline-end: 0;
      width: 100% !important;
      position: relative;

      button {
        margin-bottom: 0 !important;
        background: transparent;
      }

      .artistName {
        border: none;
        color: white;
        font-size: 30px;
        font-weight: 700;
      }
    }
  }

  .ant-card-hoverable {
    .ant-card-cover {
      display: flex;
      flex-wrap: wrap;

      .img-wrapper {
        // margin-right: 1rem;

        .ant-image {
          width: 100%;
          display: flex;

          img {
            // flex: 0 0 50%;
            // width: 50%;
            min-height: 12rem;
            max-height: 12rem;
          }
        }
      }
    }
  }
}

.cardColumn {
  // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  margin: 1rem;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  height: fit-content;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
  }
}
.noArtistWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 30rem;
}


.artistCardColumn {
  max-height: 20rem;
  max-width: 20rem;
  overflow: hidden;
  object-fit: contain;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  margin: 1rem;
  cursor: pointer;
  transition: 0.3s all ease-in-out;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
  }
}