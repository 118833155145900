//Modal Style

.formModal {
    .ant-row {
        .ant-form-item-label {
            width: 100%;
            text-align: left;
        }
    }

    .ant-modal-content {
        padding: 0;

        .ant-modal-header {
            padding: 1rem;
            background: $primary;
            margin-bottom: 0;

            .ant-modal-title {
                color: $white;
                text-align: center;
            }

        }

        .ant-modal-close {
            color: $white;

            &:hover {
                background-color: transparent;
            }
        }

        .modalBody {
            padding: 1.5rem;

            .roleButton {
                text-align: center;

                .roleIcon {
                    width: 100px;
                    height: 100px;
                    border: 2px solid $primary;
                    border-radius: 50%;
                    overflow: hidden;
                    box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);
                    margin: 0 auto;
                    padding: 1rem;
                }

                .roleTitle {
                    font-size: 1.5rem;
                    font-weight: 600;
                    margin: 1rem 0;
                }
            }

            .circleButton {
                text-align: center;

                .roleIcon {
                    width: 90px;
                    height: 90px;
                    border: 2px solid $primary;
                    border-radius: 50%;
                    overflow: hidden;
                    box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);
                    margin: 0 auto;
                    padding: 1rem;
                    // width: 100%;
                    // height: auto;
                    vertical-align: middle;
                    font-size: 30px;
                    // align-items: center;
                    // justify-content: center;
                }

                .roleTitle {
                    font-size: 0.9rem;
                    font-weight: 500;
                    margin: 1rem 0;
                }

            }

            .toSection {
                .secLable {
                    margin-bottom: 0;

                    label {
                        font-weight: 600;
                    }
                }

                .toWrap {
                    display: flex;

                    .imgSec {
                        margin-right: 1rem;

                        .ant-image {
                            width: 50px;
                            height: 50px;
                            border-radius: 50%;
                            overflow: hidden;
                            box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);
                        }
                    }

                    .detailsWrap {
                        .toName {
                            margin-bottom: 0;
                            font-weight: 600;
                            text-transform: capitalize;
                        }

                        .ant-rate {
                            font-size: 0.875rem;
                        }
                    }
                }
            }

        }
    }

    .modal-body {
        .imageWrapper {
            margin: 2rem 0;
.imgSection{
    width: 100%;
    padding:  0 1rem;
            img {
                height: 10rem;
                width: 15rem;
                margin: 0 0.5rem;
                border-radius: 2px;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            }
        }
        .videoSection {
            margin: 2rem 0;
            width: 100%;
            padding:  0 1rem;
        }
    }
}
}

.albumImgCard {
    box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    .ant-card-body {
        padding: 0;
        overflow: hidden;
    }
}


.welcomeModal {
    box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);

    .ant-modal-content {
        padding: 0;
        overflow: hidden;
        border-radius: 10px;

        .ant-modal-close {
            display: none;
        }

        .modalBody {
            .ant-result-info {
                padding: 0;

                .ant-result-icon {
                    background: $primary;
                    padding: 2rem 1rem;
                    margin-bottom: 2rem;

                    .anticon-smile {
                        color: $white;
                    }
                }

                .ant-result-title {
                    font-size: 1.5rem;
                    font-weight: 700;
                    color: $primary;
                }

                .ant-result-subtitle {
                    color: $black;
                    padding: 0 1rem;
                }

                .ant-result-extra {
                    margin-bottom: 2rem;

                    .ant-btn {
                        background: $primary;
                        border: 1px solid $primary;
                        color: $white;
                        box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);
                        transition: all 0.3s ease-in-out;

                        &:hover {
                            background: $white;
                            color: $primary;
                        }
                    }
                }
            }
        }
    }
}