// Write Review Style

.wrireReviewWrapper {
    .innerWrap {
        padding: 1.5rem 0;

        .titleRow {
            text-align: center;
            margin-bottom: 1.5rem;

            .pageTitle {
                color: $primary;
                font-weight: 600;
                font-size: 2.125rem;
            }
        }

        .reviewFormWrap {
            .reviewForm {
                box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);

                .topWrap {
                    .profileWrap {
                        display: flex;
                        margin-bottom: 1.5rem;

                        .imgSection {
                            width: 100px;
                            height: 100px;
                            overflow: hidden;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);
                            margin-right: 1rem;
                        }

                        .nameSection {
                            .name {
                                font-size: 1.75rem;
                                color: $primary;
                                font-weight: 600;
                                text-transform: capitalize;
                            }
                        }
                    }
                }
            }
        }
    }
}