//Global Font
$Open : 'Open Sans';

//Colors
$primary: #d62b47;
$white: #FFFFFF;
$black: #000000;
$success: #449D44;
$info: #31B0D5;
$warning: #EC971F;
$danger: #C9302C;
$orange: #ff66;
$tag: #f7f7f7;
$priceCard: #e68091