//Header style

.navBar {
    .navbar {
        background: $white;
        box-shadow: 0px 2px 12.18px 1.82px rgba(0, 0, 0, 0.14);
        padding: 0;
        
        .navbar-brand {
            width: 80px;
        }

        .navbar-nav {
            .nav-item {
                margin-right: 0.5rem;
                .nav-link {
                    font-size: 1rem;
                    color: $black;
                    display: flex;
                    align-items: center;
                    transition: all 0.3s ease-in-out;
                    &:hover {
                        color: $primary;
                    }
                    .iconImg {
                        width: 16px;
                        margin-right: 0.5rem;
                    }
                }
                .navBtn {
                    background: $success;
                    border: 1px solid $success;
                    border-radius: 10px;
                    color: $white;
                    transition: all 0.3s ease-in-out;
                    &:hover {
                        background: $white;
                        color: $success;
                    }
                }
                .avatarDropdown {
                    padding-inline-start: 0.625rem;
                    .ant-avatar {
                        width: 25px;
                        height: 25px;
                        line-height: 25px;
                    }
                }
            }
        }
    }
}

.dropItem {
    display: flex;
    align-items: center;
}

.msgBtn {
    a {
        background: $white;
        border: 1px solid $primary;
        border-radius: 10px;
        color: $primary !important;
        transition: all 0.3s ease-in-out;
        &:hover {
            background: $primary;
            color: $white !important;
        }
    }
}