//Banner Style

//HomapageBanner
.bannerWrapper {
    .innerWrap {
        .bannerSec {
            .bannerContent {
                text-align: center;

                .secTitle {
                    color: $white;
                    font-size: 3.75rem;
                    font-weight: bold;
                    margin-bottom: 1rem;

                    .colorText {
                        color: $primary;
                    }
                }

                .subText {
                    color: $white;
                    margin-bottom: 2.5rem;
                }

                .searchBar {
                    position: relative;
                    width: 70%;
                    margin: 0 auto;
                }

                .searchBtn {
                    position: absolute;
                    top: 0.313rem;
                    right: 0.313rem;
                }
            }
        }
    }
}