//Theme Style

.ant-layout-content {
    background: $white;
}

.ant-divider {
    margin: 1rem 0;
}

.ant-checkbox-input {
    .ant-checkbox-inner {
        box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);
    }
    
}

.ant-checkbox-checked {
    .ant-checkbox-inner {
        background-color: $primary;
        border-color: $primary;
        box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);
    }
}

.ant-radio-wrapper {
    .ant-radio-checked {
        .ant-radio-inner {
            background-color: $primary;
            border-color: $primary;
            box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);
        }
    }
}

.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner,
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-radio-wrapper:hover .ant-radio-inner {
    border-color: $primary;
}

.backToTop {
    background: $primary;
    color: $white;
    border-radius: 0.625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 1.563rem;
    bottom: 0.875rem;
    box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);
}

.ant-divider-horizontal {
    &.ant-divider-with-text-left {
        &::before {
            width: 1%;
        }
    }
    .ant-divider-inner-text {
        font-size: 1.5rem;
    }
}

.ant-form-item-label  {
    label {
        font-weight: 600;
        color: $black;
    }
}

.ant-form-item-required {
    flex-direction: row-reverse;
    &::before {
        margin-inline-end: 0;
        margin-inline-start: 4px;
    }
}

.ant-select-single:not(.ant-select-customize-input) {
    .ant-select-selector {
        height: 100%;
        border: 0;
        border-radius: 10px;
    }
}

.ant-select-single {
    .ant-select-selector {
        .ant-select-selection-placeholder {
            line-height: 45px;
        }
    }
}

.ant-input-group-compact {
    .ant-input {
        width: 100%;
        height: 45px;
        border: 0;
        box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);
        &:first-child {
            width: 30% !important;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        &:last-child {
            border-left: 1px solid #c1c1c1;
            width: 70% !important;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
}

.ant-select-multiple {
    .ant-select-selector {
        min-height: 45px;
        height: auto;
        border-radius: 10px;
        border: 0 !important;
        box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);
        padding-inline-start: 1rem;
    }
}

.ant-select-dropdown {
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: $priceCard;
        margin-bottom: 1px;
        .ant-select-item-option-state {
            color: $primary;
        }
    }
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: $priceCard;
    }
    .ant-select-item-option-content {
        font-weight: 500;
    }
}

.ant-checkbox-group {
    flex-wrap: wrap;
    gap: 15px 0;

    .ant-checkbox-wrapper {
        width: 25%;
        margin-inline-start: 0;
       
    }
    @media (max-width:574px) {
        .ant-checkbox-wrapper {
        width: 48%;
         margin-inline-start: 0;
        }
    }
}

.ant-dropdown-placement-bottomRight {
    top: 85px !important;

    .ant-dropdown-menu-root {
        width: 150px;

        li {
            padding: 0.5rem 1rem;
        }
    }
}

.ant-input-group-compact .ant-input:last-child {
    width: 100% !important;
}

.frmCntrl {
    border-left: 0 !important;
    width: 100% !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.fmCntrl {
    border-left: 0 !important;
    width: 100% !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.ant-select-single {
    .ant-select-selector {
        .ant-select-selection-item {
            line-height: 45px;
        }
    }
}

.ant-spin {
    .ant-spin-dot-item {
        background-color: $primary;
    }
}

.ant-form-item-explain-error {
    font-size: 0.75rem;
}

.ant-steps {
    .ant-steps-item-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .ant-steps-item-process,
    .ant-steps-item-finish {
        .ant-steps-item-icon {
            width: 40px;
            height: 40px;
            line-height: 40px;
            background-color: $primary;
            border-color: $primary;
            .ant-steps-icon {
               color: $white;
            }
        }
        .ant-steps-item-title {
            font-weight: 700;
            color: $black;
            &::after {
                top: -1rem;
            }
        }
    }
    .ant-steps-item-active {
        .ant-steps-item-icon {
            background-color: $success;
            border-color: $success;
        }
    }
    .ant-steps-item-wait {
        .ant-steps-item-icon {
            width: 40px;
            height: 40px;
            line-height: 40px;
        }
    }
}