// Footer Style

.footer {
    background: $black;
    color: $white;
    position: relative;

    .footerInner {
        padding: 3.125rem 0;
        border-bottom: 1px solid $white;

        .footerColLogo {
            width: 110px;
        }

        .footerColTitle {
            h5 {
                font-size: 1.125rem;
                font-weight: bold;
                margin-bottom: 0;
                padding-bottom: 1rem;
                border-bottom: 1px solid $white;
            }
        }

        .footerContent {
            padding-top: 1rem;

            .footerMenu {
                padding-left: 0;

                .ftrMenuitems {
                    padding: 0.5rem 0;

                    .ftrMenuLink {
                        color: $white;
                        text-decoration: none;
                        &:hover {
                            color: $primary;
                        }
                    }
                }
            }
            .socialIcons {
                display: flex;
                align-items: center;

                .socialIcn {
                    width: 35px;
                    height: 35px;
                    font-size: 1rem;
                    color: $primary;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 0.625rem;
                    background: $white;
                    margin-right: 1rem;
                    box-shadow: 0px 2px 12.18px 1.82px rgba(0, 0, 0, 0.14);
                    transition: all 0.3s ease-in-out;
                    &:hover {
                        background: $primary;
                        color: $white;
                    }
                }
            }
        }
    }

    .copywriteSection {
        padding: 1rem 0;
        p {
            margin-bottom: 0;
            .devLink {
                color: $white;
                text-decoration: none;
                &:hover {
                    color: $primary;
                }
            }
        }
    }
}