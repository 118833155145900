//Homepage Style

.homeWrapper {
    .homeInner {
        .contentWrap {
            padding: 1.125rem 0;

            .listingWrapper {
                .liistWrapper {

                    .profileCard {
                        box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);
                        overflow: hidden;
                        transition: all 0.3s ease-in-out;
                        width: 100%;

                        &:hover {
                            box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.3);

                            // transform: scale(1.01);
                            & .cardCoverImg {
                                filter: blur(2px);
                            }
                        }

                        .ant-card-body {
                            padding: 0;

                            .cardContent {
                                .innerContent {
                                    display: flex;
                                    flex-direction: row;

                                    @media only screen and (max-width: 767px) {
                                        display: block;
                                    }

                                    .cardCoverImg {
                                        width: 30%;

                                        @media only screen and (max-width: 767px) {
                                            width: 100%;
                                        }

                                        .ant-carousel {
                                            .slick-slider {
                                                .slick-list {
                                                    .slides {
                                                        width: 100%;
                                                        height: 100%;

                                                        .ant-image {
                                                            width: 100%;
                                                            height: 100%;

                                                            img {
                                                                object-fit: cover;
                                                                height: 284px;
                                                            }
                                                        }
                                                    }
                                                }

                                                .slick-dots {
                                                    display: none !important;
                                                }
                                            }
                                        }
                                    }

                                    .profileDetails {
                                        width: 50%;
                                        padding: 1rem;

                                        @media only screen and (max-width: 767px) {
                                            width: 100%;
                                        }

                                        .artistRate {
                                            padding-left: 1rem;
                                        }

                                        .artistProfile {
                                            position: relative;
                                            right: 8%;

                                            @media only screen and (max-width: 767px) {
                                                right: 0%;
                                            }

                                            .ant-avatar {
                                                width: 50px;
                                                height: 50px;
                                                border: 2px solid $white;
                                                background: $white;
                                                box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);
                                            }

                                            .artistName {
                                                font-size: 1.125rem;
                                                color: $primary;
                                                font-weight: bold;
                                                text-decoration: none;
                                                text-transform: capitalize;
                                                margin-left: 0.725rem;
                                            }
                                        }

                                        .address {
                                            font-weight: 500;
                                            padding-left: 1.125rem;
                                        }

                                        .tagSection {
                                            padding-left: 1rem;

                                            .ant-tag {
                                                background: $tag;
                                                font-weight: 600;
                                                border: 0;
                                            }
                                        }

                                        .profileBtn {
                                            .profileLink {
                                                font-size: 0.75rem;
                                                text-decoration: none;
                                                color: $primary;
                                                font-weight: 500;
                                            }
                                        }
                                    }

                                    .btnSection {
                                        width: 20%;
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                        padding: 1rem;

                                        @media only screen and (max-width: 767px) {
                                            width: 100%;
                                        }

                                        .SavedArtistBtn {
                                            background: white;
                                            color: #d62b47;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .gridCard {
                        box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);
                        overflow: hidden;
                        transition: all 0.3s ease-in-out;

                        &:hover {
                            box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.2);

                            // transform: scale(1.01);
                            & .cardCoverImg {
                                filter: blur(2px);
                            }
                        }

                        .ant-card-meta {
                            .ant-card-meta-avatar {
                                .ant-avatar-image {
                                    width: 50px;
                                    height: 50px;
                                    border: 2px solid $white;
                                    box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);
                                }
                            }

                            .ant-card-meta-detail {
                                .ant-card-meta-title {
                                    margin-bottom: 0;

                                    a {
                                        font-size: 1.125rem;
                                        color: $primary;
                                        font-weight: bold;
                                        text-decoration: none;
                                        text-transform: capitalize;
                                    }
                                }

                                .ant-card-meta-description {
                                    .ant-rate {
                                        margin-bottom: 0.5rem;
                                    }

                                    .address {
                                        font-weight: 500;
                                        color: $black;
                                    }

                                    .ant-tag {
                                        background: $tag;
                                        font-weight: 600;
                                        border: 0;
                                    }
                                }
                            }
                        }

                        .ant-card-actions {
                            li {
                                .profileLink {
                                    font-size: 0.75rem;
                                    text-decoration: none;
                                    color: $primary;
                                    font-weight: 500;
                                }

                                span {
                                    color: $black;

                                    &:hover {
                                        color: $primary;
                                    }
                                }
                            }
                        }

                        .messageIcon {
                            cursor: pointer;

                            &:hover {
                                color: #d62b47;
                            }
                        }
                    }

                    .paginationWrapper {
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;
                        margin: 1rem 0.2rem;
                        align-items: center;

                        .ant-pagination-item-active {
                            background: #d62b47;
                            color: white;
                            border: none;
                        }
                    }
                }
            }
        }
    }
}