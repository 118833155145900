// User Profile Style

.userProfileWrapper {
    .profileWrapInner {
        padding: 1.5rem 0;

        @media (max-width: 500px) {
            .sliderWrapper {
                height: 243px;
            }
        }

        .sliderWrapper {
            margin-bottom: 1rem;

            .slideWrap {
                .slides {
                    // height: 500px;
                    border-radius: 1px;
                    overflow: hidden;

                    @media only screen and (min-width: 768px) and (max-width: 991px) {
                        height: 350px;
                    }

                    @media only screen and (max-width: 767px) {
                        height: 200px;
                    }
                }
            }

            .slick-dots {
                bottom: 0;
            }
        }

        .infoWrapper {
            .infoWrapInner {
                .infoCard {
                    box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);

                    @media (max-width: 500px) {
                        .ant-card-body {
                            padding: 12px 2px;
                        }
                    }

                    .infoSection {
                        display: flex;
                        justify-content: space-between;

                        @media only screen and (max-width: 767px) {
                            flex-direction: column;
                        }

                        .information {
                            display: flex;

                            .imgSection {
                                width: 100px;
                                height: 100px;
                                overflow: hidden;
                                border-radius: 10px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);
                                margin-right: 1rem;
                            }

                            .nameSection {
                                .name {
                                    font-size: 1.75rem;
                                    color: $primary;
                                    font-weight: 600;
                                    text-transform: capitalize;
                                }

                                p {
                                    margin-bottom: 0;
                                    font-weight: 500;
                                    text-transform: capitalize;
                                }
                            }
                        }

                        .buttonSec {
                            display: flex;
                            align-items: flex-end;
                            flex-wrap: wrap;
                            justify-content: space-between;

                            .itemgetReview {
                                margin-right: 8px;

                            }

                            @media(max-width: 574px) {
                                .itemgetReview {
                                    width: 100%;
                                    margin: 10px 0px;
                                    max-width: 48%;
                                    margin-right: 0 !important;
                                }
                            }

                        }
                    }


                }
            }
        }

        @media(max-width:770px) {
            .tabSection {
                overflow-x: scroll;
            }
        }

        .tabSection {
            margin: 1rem 0;

            .linkGroup {
                .ant-anchor {
                    padding-inline-start: 0;
                    display: flex;
                    background: $tag;
                    padding: 0.313rem;
                    border-radius: 5px;

                    // overflow-x: scroll;
                    .ant-anchor-ink {
                        display: none;
                    }

                    .ant-anchor-link {
                        padding-inline-start: 0;
                        transition: all 0.3s ease-in-out;

                        &.ant-anchor-link-active {
                            background: $white;
                            border-radius: 5px;
                        }

                        &:hover {
                            background: $white;
                            border-radius: 5px;
                        }

                        .ant-anchor-link-title {
                            text-decoration: none;
                            font-weight: 600;
                            padding: 0 1.5rem;

                            &.ant-anchor-link-title-active {
                                color: $black;
                            }

                        }
                    }
                }
            }
        }

        .aboutWrapper {
            margin-bottom: 2rem;

            .aboutWrapInner {
                .businessCard {
                    box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);
                }
            }
        }

        .locationWrapper {
            margin-bottom: 2rem;

            .locationWrapInner {
                .businessCard {
                    box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);
                }
            }
        }

        .portfolioWrapper {
            margin-bottom: 2rem;

            .ant-card-cover {
                max-height: 250px;
                overflow: hidden;
            }
        }

        .businessWrapper {
            margin-bottom: 2rem;

            .businessWrapInner {
                .businessCard {
                    box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1) !important;

                    .bsDetails {
                        display: flex;
                        padding-bottom: 0.75rem;
                        border-bottom: 1px solid $tag;

                        .bsDetailsIcon {
                            display: inline-block;
                            width: 40px;
                            height: 40px;
                            background: $white;
                            border: 1px solid $primary;
                            border-radius: 5px;
                            color: $primary;
                            text-align: center;
                            line-height: 32px;
                            box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);
                            font-size: 1.125rem;
                        }

                        .innerDetails {
                            p {
                                color: $black;
                                font-weight: 600;
                                margin-bottom: -2px;
                            }

                            span {
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }

        .credentialsWrapper {
            margin-bottom: 2rem;

            .credentialsWrapInner {
                .crGroup {
                    display: flex;

                    .crWrap {
                        width: 120px;
                        height: 120px;
                        overflow: hidden;
                        border: 1px solid $tag;
                    }

                    .portfolioCard {
                        .ant-card-cover {
                            height: 203px;
                        }
                    }
                }
            }
        }

        .reviewsWrapper {
            .reviewsWrapInner {
                .reviewTopSec {
                    .contentSection {
                        .leftSec {
                            h2 {
                                font-size: 3.75rem;
                                font-weight: 600;
                            }

                            .ant-rate {
                                margin-bottom: 1.5rem;
                            }

                            p {
                                color: $black;
                                font-weight: 500;
                            }
                        }

                        .rightSec {
                            .rateSec {
                                margin-bottom: 1rem;

                                .firstText {
                                    display: inline-block;
                                    width: 41%;
                                    font-size: 1.125rem;
                                    font-weight: 600;
                                }

                                .lastText {
                                    display: inline-block;
                                    width: 25%;
                                    font-size: 1.125rem;
                                    font-weight: 600;
                                    margin-left: 1.5rem;
                                }
                            }
                        }
                    }
                }

                .commentsRow {
                    margin: 1.5rem 0;

                    .commentsInner {
                        display: flex;
                        padding: 1.5rem 0 1rem 0;
                        border-bottom: 1px solid $tag;

                        .rvAvatar {
                            margin-right: 1rem;

                            .ant-image {
                                display: block;
                                width: 50px;
                                height: 50px;
                                overflow: hidden;
                                border: 1px solid $tag;
                                border-radius: 50%;
                                box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);
                            }

                        }

                        .innerCom {
                            .comName {
                                font-size: 1.125rem;
                                color: $black;
                                font-weight: 600;
                                margin-bottom: 0;
                            }

                            .ant-rate {
                                margin-bottom: 0.5rem;
                            }

                            .comDesc {
                                color: $black;
                                font-weight: 500;
                                margin-bottom: 0.5rem;
                            }
                        }
                        .actionBtnWrapper {
                            width: 80%;
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            .editBtn {
                                margin-right: 1rem;
                                box-shadow: none !important;
                                button {
                                border: none !important;
                                box-shadow: none !important;
                                &:hover {
                                    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                                    color: #d62b47;
                                }
                                }
                            }
                            .dltBtn {
                                button {
                                    border: none !important;
                                    box-shadow: none !important;
                                    &:hover {
                                        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                                        color: #d62b47;
                                    }
                                    }  
                            }
                        }
                    }
                }
            }
        }
    }
}

.editProfileWrapper {
    .innerWrap {
        padding: 1.5rem 0;

        .profileCard {
            box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);

            .ant-card-body {
                padding: 0 0 1rem 0;

                .infoSection {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    margin-bottom: 2rem;

                    @media only screen and (max-width: 767px) {
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: start;
                    }

                    .information {
                        display: flex;

                        .imgSection {
                            margin-right: 0.75rem;
                            position: relative;

                            .ant-upload-select {
                                width: 120px;
                                height: 120px;
                                border-radius: 5px;
                                background: $primary;
                                font-size: 1.5rem;
                                font-weight: 600;
                                border: 0;
                                color: $white;
                                margin-bottom: 0;
                                position: relative;

                                @media only screen and (max-width: 767px) {
                                    width: 90px;
                                    height: 90px;
                                }

                                .editBtn {
                                    width: 25px;
                                    height: 25px;
                                    background: $white;
                                    border-radius: 5px;
                                    font-size: 0.75rem;
                                    color: $primary;
                                    position: absolute;
                                    right: 5px;
                                    bottom: 5px;
                                    box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                }
                            }
                        }

                        .nameSection {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;

                            .name {
                                font-weight: 600;
                            }

                            p {
                                margin-bottom: 0;
                            }
                        }
                    }

                    .buttonSec {
                        @media only screen and (max-width: 767px) {
                            margin-top: 1rem;
                        }
                    }
                }
            }
        }
    }
}

.editArtistWrapper {
    .innerWrap {
        padding: 1.5rem 0;

        .profileCard {
            box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);

            .ant-card-body {
                padding: 0;

                .infoSection {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    margin-bottom: 2rem;

                    @media only screen and (max-width: 767px) {
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: start;
                    }

                    .information {
                        display: flex;

                        .imgSection {
                            margin-right: 0.5rem;
                            position: relative;

                            .ant-upload-select {
                                width: 120px;
                                height: 120px;
                                border-radius: 5px;
                                background: $primary;
                                font-size: 1.5rem;
                                font-weight: 600;
                                border: 0;
                                color: $white;
                                margin-bottom: 0;
                                position: relative;
                                border: 2px solid $white;
                                padding: inherit;

                                @media only screen and (max-width: 767px) {
                                    width: 90px;
                                    height: 90px;
                                }

                                .editBtn {
                                    width: 25px;
                                    height: 25px;
                                    background: $white;
                                    border-radius: 5px;
                                    font-size: 0.75rem;
                                    color: $primary;
                                    position: absolute;
                                    right: 5px;
                                    bottom: 5px;
                                    box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                }
                            }
                        }

                        .nameSection {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;

                            .name {
                                font-weight: 600;
                                color: $white;
                            }

                            p {
                                margin-bottom: 0;
                                color: $white;
                            }
                        }
                    }

                    .buttonSec {
                        @media only screen and (max-width: 767px) {
                            margin-top: 1rem;
                        }
                    }
                }

                .updateBtnWrapper {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 2rem 0;

                    button {
                        background: #d62b47 !important;
                        padding: 0.6rem 1.5rem;
                        height: auto;
                        border-radius: 25px;
                        color: white;
                        transition: 0.2s all ease-in-out;

                        &:hover {
                            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                            transform: translateY(-0.15em);
                        }

                    }
                }

                .editButton {
                    position: absolute;
                    right: 1rem;
                }

                .videoWrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .videoSection {
                        padding: 0.5rem;
                        border-radius: 8px;
                        border: 1px solid #cecece;

                        .buttonWrapper {
                            width: 100%;

                            button {
                                background-color: transparent;
                                color: black;
                                width: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: space-around;
                                border: none;

                                svg {
                                    &:hover {
                                        color: #d62b47;
                                    }
                                }
                            }
                        }
                    }

                    .hideVideo {
                        display: none;
                    }

                }

                .removeSection {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 10vh;
                }
            }
        }
    }
}

.sliderWrapper {
    position: relative;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    overflow: hidden;

    // height: 450px;
    .ant-image {
        object-fit: fill !important;
        display: flex !important;
        max-height: 54vh;

        img {
            object-fit: fill;
        }
    }
}

.coverWrapper {

    // padding: 1.125rem;
    .infoSection {
        .information {
            position: absolute;
            top: -150px;
        }

        @media (max-width:550px) {
            .information {
                position: absolute;
                top: -120px;
            }
        }
    }
}

.cardBody .tabsWrapper {
    position: relative;

    .nav-tabs {
        border-bottom: 0;
        background: $tag;
        padding: 0.5rem;

        .nav-item {
            margin-right: 0.5rem;

            .nav-link {
                color: $primary;

                &:hover {
                    border-color: $primary;
                    border-radius: 5px;
                }

                &.active {
                    border-radius: 5px;
                    background: $primary;
                    color: $white;
                    border-color: $primary;
                }
            }
        }
    }
}

.albumCard {
    border-radius: 10px;
    box-shadow: 0px 2px 6.65px 0.35px rgba(0, 0, 0, 0.1);

    .albumImg {
        border-bottom: 1px solid $tag;

        .ant-image {
            max-height: 316px;
            overflow: hidden;
            height: 100%;
            width: 100%;

            img {
                max-height: 310px;
                object-fit: contain;
            }
        }
    }

    .albumName {
        margin-bottom: 0;
        padding: 1rem;
        font-size: 1rem;
        font-weight: 600;
    }
}

.editBtn .submitBtn {
    background-color: #007bff; 
    color: white;
    margin-right: 10px; 
  }
  
  
  .dltBtn .submitBtn {
    background-color: #ff3333; 
    color: white; 
  }

  .rr {
    height: auto !important;
    box-shadow: none !important;
    justify-content: end !important;
  }