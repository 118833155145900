//ABout Business Page STy;e

.businessWrapper {
    .businessWrapInner {
        padding: 1.5rem 0;

        .titleRow {
            text-align: center;
            margin-bottom: 1.5rem;

            .pageTitle {
                color: $primary;
                font-weight: 600;
                font-size: 2.125rem;
            }
        }
        .rightSection {
            .formWrap {
                .customSelectCountry {
                    .ant-select-selection-search {
                        top: 6px;
                    }
                }
            }
        }
    }
}

.MuiStepper-root  {
    .MuiStep-root {
        .MuiStepConnector-root {
            top: 20px;
        }
        .MuiStepButton-root {
            .MuiSvgIcon-root {
                width: 40px;
                height: 40px;
                color: #dcdcdc;
                &.Mui-active {
                    color: $primary;
                    .MuiStepIcon-text {
                        fill: $white;
                    }
                }
                .MuiStepIcon-text {
                    fill: $black;
                }
            }

            .MuiStepLabel-label {
                font-weight: 700;
                color: $black;
            }
        }
    }
}

.stepWrapper {
    .stepwrapInner {
        .stepTitle {
            margin-bottom: 2rem;
            h4 {
                font-size: 1.125rem;
                font-weight: 600;
            }

            p {
                color: $black;
            }
        }
        .customSelect {
            .ant-select-selection-search {
                top: 6px;
            }
        }
    }
}

.btnGroup {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    @media (max-width:600px) {
       
            .d-flex {
                margin: 5px;
           
        }
    }
}

.customDragger {
    .ant-upload-wrapper {
        &.ant-upload-picture-card-wrapper {
            .ant-upload {
                &.ant-upload-select {
                    width: 100%;
                    height: 100%;
                    border-color: $primary;
                    border-radius: 0;
                    padding: 5rem 0;

                    .anticon-inbox {
                        font-size: 3rem;
                        color: $primary;
                    }

                    .ant-upload {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
    .ant-upload-select {
        &:hover {
            border-color: $primary !important;
        }

        .ant-upload-btn {
            padding: 5rem 0;

            .ant-upload-drag-icon {
                .anticon-inbox {
                    color: $primary;
                }
            }
        }
    }
}

.flex-wrap {
    .ant-upload-select {
        border-color: $primary !important;
    }
    
    .ant-upload-list-item-container {
        .ant-upload-list-item {
            border: 1px dashed $primary !important;
        }
        
    }
}